<template>
  <div>
    <div class="_BoxHeader">
      <span>发现问题个数</span>
      <el-button
        @click="$router.go(-1)"
        icon="el-icon-back"
        >返回</el-button
      >
    </div>
    <el-table
      :header-cell-style="{
        background: '#e2d2d2',
        height: '80px',
        color: '#000000',

        border: '1px solid tan',
      }"
      style="font-size: 18px"
      :data="tableData"
    >
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="camareName" label="位置"> </el-table-column>
      <el-table-column prop="saveTime" label="时间"> </el-table-column>
      <el-table-column prop="remark" label="隐患问题点"> </el-table-column>
      <el-table-column label="照片">
        <template slot-scope="scope">
          <div>
            <el-button type="success" @click="getDataimg(scope.row)"
              >获取图片</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="整改状态"> </el-table-column>
      <el-table-column prop="" label="原因分析"> </el-table-column>
      <el-table-column prop="" label="整改措施"> </el-table-column>
      <el-table-column prop="" label=" 资金投入"> </el-table-column>
      <el-table-column prop="" label="整改后照片"> </el-table-column>
      <el-table-column prop="" label="整改完成时间"> </el-table-column>
    </el-table>
    <el-dialog title="图片" :visible.sync="dialogVisible" width="30%">
      <el-image
        style="width: 150px; height: 150px; margin-left: 20px"
        :fit="fill"
        :src="'http://10.0.1.76:5726/' + this.img"
        :preview-src-list="BigImg"
      >
      </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      tableData: '',
      userName: this.$route.query.userName,
      userNo: this.$route.query.userNo,
      img: '',
      BigImg: '',
      dialogVisible: false,
    }
  },
  created() {},
  mounted() {
    this.login()
  },
  methods: {
    login() {
      if (this.$route.query.userName) {
        this.userName = this.$route.query.userName
        this.userNo = this.$route.query.userNo
        sessionStorage.setItem('userName', this.userName)
        sessionStorage.setItem('userNo', this.userNo)
        if (this.userName.length <= 5) {
          let url = 'http://43.139.69.79:90/api/DoLogin/DoNoPwdMyLogin?UserNo='
          this.NoPwdlogin(url)
        } else {
          let url = 'http://43.139.69.79:90/api/DoLogin/DoNoPwdLogin?UserNo='
          this.NoPwdlogin(url)
        }
      }
    },
    NoPwdlogin(url) {
      get(url + this.userNo + '&UserName=' + this.userName)
        .then((resp) => {
          if (resp.code == 200) {
            sessionStorage.setItem('userInfo', JSON.stringify(resp.data))
            sessionStorage.setItem('token', JSON.stringify(resp.data.userToken))
          }
        })
        .then(() => {
          this.toOpenImgList()
        })
    },
    toOpenImgList() {
      get(
        'http://43.139.69.79:90/api/ImageRemark/GetByUserId?UserNo=' +
          this.$route.query.userNo
      ).then((res) => {
        res.data.forEach((i) => {
          i.saveTime = i.saveTime.split('T')[0]
        })
        this.tableData = res.data
      })
    },
    getDataimg(row) {
      console.log(row)
      this.dialogVisible = true
      var vdata = {
        companyName: row.companyName,
        userNo: row.userNo,
        id: row.id,
      }
      get(
        'http://43.139.69.79:90/api/RemarkImageList/GetByIndexCode?UserNo=' +
          vdata.userNo +
          '&CompName=' +
          vdata.companyName +
          '&RemarkId=' +
          vdata.id
      ).then((res) => {
        this.BigImg = []
        console.log(res)
        this.img = res.data.picAddr
        var e = 'http://10.0.1.76:5726/' + this.img
        this.BigImg.push(e)
      })
    },
  },
}
</script>

<style scoped>
</style>
